import React, { useRef, useState } from 'react';
import { HeaderStyle } from './components/Header.style';
import { _logoutFunction, storeAssigned, handleRoute, REFFERAL_VALUES, CUSTOMER_TYPE } from '../../../services/Methods/normalMethods';
import NavLinkComp from '../../../Components/NavLinkComp';
import withRouter from '../../../services/HOC/withRouterHOC';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { MyAccoutnAPI } from '../../../services/Redux/Reducer/LoginSlice';
import { CATEGROY_LIST } from '../../../services/Methods/commonPayload';
import _ from 'lodash';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import Avatar from '@mui/material/Avatar';
import CountryFlagDropdown from "./components/CountryFlagDropdown";
import ReactCountryFlag from 'react-country-flag';
import useOutsideAlerter from '../../../services/HOC/useOutsideAlerter';
const Header = ({ token, location, alluserData, cartValues, navigate, selectedStore, setSelectedStore, countyObjects, countryCodes }) => {

    const [toggle, setToggle] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isActiveAbout, setIsActiveAbout] = useState(false);
    const [isActiveEnrol, issetActiveEnrol] = useState(false);
    const [showCountries, setShowCountries] = useState(false);

    const dispatch = useDispatch()

    const handleClick = () => setIsActive(current => !current);
    const handleClickAbout = () => setIsActiveAbout(current => !current);
    const handleClickEnrolment = () => issetActiveEnrol(current => !current);

    function MyAccount() {
        dispatch(MyAccoutnAPI())
    }
    const CUSTOMER_TYPE = localStorageCall().getItem('customerTypeDescr');

    function stringAvatar(firstName, lastName) {
        return {
            children: `${firstName ? firstName?.charAt?.(0) : ""}${lastName ? lastName?.charAt?.(0) : ""}`,
        };
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setShowCountries(false);
    });

    return (
        <HeaderStyle>
            <div className={classNames('header-section', { 'homePageHeader': (location.pathname === `/${storeAssigned()}`) })}>
                <div className="container">
                    <div className="row" style={{ position: "relative" }} ref={wrapperRef}>
                        {token?.token
                            ?
                            <div className='user_details desktop'>Welcome, {alluserData?.fullName && <Avatar sx={{
                                bgcolor: '#2A533F',
                                color: '#fff',
                                border: "1px solid #fff"
                            }} {...stringAvatar(alluserData?.firstName, alluserData?.lastName)} />}</div> :
                            <div className='refer-name'>
                                {REFFERAL_VALUES?.name && <span className='user_details-1'>Shopping with: {REFFERAL_VALUES?.name}</span>}
                            </div>
                        }

                        <div className='cstm-country'>
                            <button className="" onClick={() => {
                                setShowCountries(!showCountries);
                            }}>
                                <ReactCountryFlag countryCode={selectedStore?.defaultCode || 'US'} svg />
                            </button>
                            <CountryFlagDropdown {...{ selectedStore, setSelectedStore, countyObjects, showCountries, setShowCountries }} />
                        </div>

                        <div className={toggle ? 'navbar expanded' : 'navbar'}>
                            <div className="navbar-inner">
                                <a className="brand" href={`/${storeAssigned()}`}>
                                    <img src={require('./assets/headerLogo.png') || ""} alt="headerLogo" />
                                </a>
                            </div>
                            <div className='cstm-country mobile-country' >
                                <button className="" onClick={() => {
                                    setShowCountries(!showCountries);
                                }}>
                                    <ReactCountryFlag countryCode={selectedStore?.defaultCode || 'US'} svg />
                                </button>
                                <CountryFlagDropdown {...{ selectedStore, setSelectedStore, countyObjects, showCountries }} />
                            </div>
                            <button
                                className="toggle"
                                onClick={() => setToggle((prev) => !prev)}
                            >
                                {token?.token &&
                                    <div className='user_details mobile_user'>Welcome, {alluserData?.fullName && <Avatar sx={{
                                        bgcolor: '#2A533F',
                                        color: '#fff',
                                        border: "1px solid #fff"
                                    }} {...stringAvatar(alluserData?.firstName, alluserData?.lastName)} />}
                                    </div>
                                }
                                {toggle ? (
                                    <MdClose style={{ width: '32px', height: '32px' }} />
                                ) : (
                                    <FiMenu style={{ width: '32px', height: '32px' }} />
                                )}
                            </button>

                            <div className={classNames("nav-collapse collapse ", { 'open_collapse_nav': toggle, "hide": !toggle })}>
                                {/* <div className={classNames("collapse navbar-collapse ", { 'open_collapse_nav': toggle, "hide": !toggle })} id="navbar-collapse-1"> */}

                                <ul className="nav">
                                    <li>
                                        <NavLinkComp url={`/${storeAssigned()}`} activeClassName={location?.pathname === `/${storeAssigned()}` ? 'active' : ''} >
                                            <span onClick={() => setToggle(false)}>Home</span>
                                        </NavLinkComp>
                                    </li>
                                    <li className={isActive ? 'dropdown active-drop' : 'dropdown'} onClick={handleClick}>
                                        <NavLinkComp url={`/${storeAssigned()}/shop`} activeClassName='active' >
                                            <span className="dropdown-toggle" data-toggle="dropdown">
                                                Products
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </NavLinkComp>

                                        <ul onClick={() => setToggle(false)} className="dropdown-menu about-drop product-drop">
                                            {_.map(CATEGROY_LIST, (row) => (
                                                <li key={row?.name} onClick={() => {
                                                    navigate(`${storeAssigned()}/shop/${row?.hashname}`)
                                                }}><span>{row?.name}</span></li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className={isActiveAbout ? 'dropdown active-drop' : 'dropdown'} onClick={handleClickAbout}>
                                        <NavLinkComp url={`/${storeAssigned()}/about_us`} activeClassName='active'>
                                            <span className="dropdown-toggle" data-toggle="dropdown">
                                                About
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </NavLinkComp>

                                        <ul className='dropdown-menu about-drop' onClick={() => setToggle(false)}>
                                            <li onClick={() => handleRoute('ourStory', 'about_us')}><span>Our Story</span></li>
                                            <li onClick={() => handleRoute('ourTeam', 'about_us')}><span>Our Team</span></li>
                                            <li onClick={() => handleRoute('FAQs', 'about_us')}><span >FAQs</span></li>
                                            {/* <li><span>Donations</span></li>
                                            <li onClick={() => handleRoute('COA', 'about_us')}><span>COA</span></li>*/}
                                        </ul>

                                    </li>

                                    {/* {console.log("data")}
                                    <li className={isActiveEnrol ? 'dropdown active-drop' : 'dropdown'} onClick={handleClickEnrolment}>
                                        <NavLinkComp url={`/${storeAssigned()}/reward_program`} activeClassName='active'>
                                            <span className="dropdown-toggle" data-toggle="dropdown">
                                                Rewards
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </NavLinkComp>
                                        <ul className="dropdown-menu about1-drop" onClick={() => setToggle(false)}>
                                            <li onClick={() => handleRoute('subscription', 'enrollment')}><span>Subscription</span></li> */}
                                            {/* {storeAssigned() !== 'se' && <li onClick={() => handleRoute('affiliate', 'enrollment')}><span>Affiliate Program</span></li>} */}
                                            {/* {storeAssigned() !== 'se' && <li onClick={() => handleRoute('wholesale', 'enrollment')}><span>Wholesale Program</span></li>} */}
                                            {/* <li onClick={() => navigate(`${storeAssigned()}/reward_program`)}><span>Rewards Program</span></li> */}
                                            {/* <li onClick={() => handleRoute('loyalty', 'enrollment')}><span>Rewards Program</span></li> */}
                                        {/* </ul>
                                    </li> */}

                                    <li>
                                        <NavLinkComp url={`/${storeAssigned()}/reward_program`} activeClassName='active'>
                                            <span onClick={() => setToggle(false)}>Rewards</span>
                                        </NavLinkComp>
                                    </li>

                                    <li>
                                        <NavLinkComp url={`/${storeAssigned()}/contact_us`} activeClassName='active'>
                                            <span onClick={() => setToggle(false)}>Contact</span>
                                        </NavLinkComp>
                                    </li>
                                    {/* <li style={{ paddingBottom: "14px" }}> */}
                                    <li>
                                        <span onClick={() => MyAccount()}>ABO</span>
                                    </li>
                                    {/*(token?.token && CUSTOMER_TYPE?.customerType !== 'Lead') &&
                                        <li style={{ paddingBottom: "14px" }}>
                                            <span onClick={() => MyAccount()}>ABO</span>
                                        </li>
                                        */}
                                </ul>
                                {/*<ul className="header-icon" style={{ marginBottom: 0 }}>*/}
                                <ul className="header-icon">
                                    {token?.token ?
                                        <li className=''>
                                            <span className="btn btn-login" onClick={() => _logoutFunction()}>Log out</span>
                                        </li>
                                        :
                                        <li>
                                            <NavLinkComp url={`/${storeAssigned()}/login`} activeClassName='active'>
                                                <span onClick={() => {
                                                    localStorage.removeItem('TokenEmail');
                                                    setToggle(false);
                                                }} className="btn btn-login">login</span>

                                            </NavLinkComp>
                                        </li>
                                    }
                                    <li>
                                        <NavLinkComp url={`/${storeAssigned()}/cart`} activeClassName='active'>
                                            <div className="cart-icon">
                                                {/* <i className="fa fa-shopping-bag" aria-hidden="true"></i> */}
                                                <img src={require('../Header/assets/cart.png') || ""} alt="cart" />
                                                {cartValues?.length > 0 &&
                                                    <span className="number-cart">
                                                        {cartValues?.length}
                                                    </span>
                                                }
                                            </div>
                                        </NavLinkComp>
                                    </li>

                                </ul>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderStyle>
    )
}
export default withRouter(Header);
